.booking {
  border-bottom: 1px solid $gray-lighter;
  display: block;
  margin: 0;
  padding: 10px 0;

  .row {
    border-bottom: 0;
  }

  a:active,
  a:focus,
  a:hover {
    text-decoration: none;
  }
}

.stats-box {
  text-align: center;

  p {
    line-height: 1.2;
    margin: 0;
  }

  .stats-box-count {
    font-size: 3em;
    font-weight: 600;
  }

  .stats-box-description {
    font-size: 1em;
    font-weight: 300;
    text-transform: uppercase;
  }

  @include media-breakpoint-down(xs) { 
    .stats-box-count {
      font-size: 2em;
      font-weight: 400;
    }
  
    .stats-box-description {
      font-size: 0.6em;
      font-weight: 200;
      text-transform: uppercase;
    }
  }
}
