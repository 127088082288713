$theme-primary: #12527A;
$theme-secondary: #CBDB3B;
$theme-success: #499F68;
$theme-danger: #CC2936;
$theme-warning: #E6C229;
$theme-info: #0582CA;
$border-radius: 30px;

$card-border-radius: 6px;
$card-border-color: transparent;
$card-shadow: 0px 0px 2px 1px rgba(238, 238, 238, .75);

$colors: (primary: $theme-primary, secondary: $theme-secondary, success: $theme-success, danger: $theme-danger, info: $theme-info, warning: $theme-warning);
