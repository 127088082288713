table.table.range-calendar {

    table-layout: fixed;

    & th, & td {
        border: none;
        cursor: pointer;
        font-size: 1em;
        padding: .5em;
        text-align: center;
        vertical-align: center;
        width: 100%;

        @media screen and (min-width: 768px) {
            font-size: 1.3em;
        }
    }

    & td {
        &.past {
            color: #9B9B9B;
        }

        &.selected {
            color: #fff;
            background-color: $theme-success;
        }
    }
}