.avatar-link:active,
.avatar-link:focus,
.avatar-link:hover {
  text-decoration: none;
}

.avatar {
  background-color: $gray-light;
  color: #fff;
  display: inline-block;
  letter-spacing: 1px;
  max-width: none;
  padding: 0;
  text-align: center;
  text-decoration: none;

  // Phone and up
  $size: 44px;
  font-size: 0.9rem;
  height: $size;
  line-height: $size;
  width: $size;

  // Tablet and up
  @media screen and (min-width: 768px) {
    $size: 80px;
    font-size: 2rem;
    height: $size;
    line-height: $size;
    width: $size;
  }

  &.small {
    $small_size: 42px;
    height: $small_size;
    line-height: $small_size;
    width: $small_size;
  }

  .nav-item.nav-avatar .nav-link & {
    $size: 35px;
    height: $size;
    line-height: $size;
    font-size: 0.9rem;
    width: $size;
  }
}