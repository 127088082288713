.favorites {
  margin: 10px 0;

  .avatar {
    margin-bottom: 10px;
  }

  .person-name {
    font-size: 1rem;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.overflow-wrap {
  overflow: hidden;
  position: relative;

  .overflow-container {
    overflow-x: scroll;
    position: relative;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }

    .overflow-item {
      display: inline-block;
      margin: 0 20px;
      max-width: 180px;

      @media only screen and (min-width: 768px) {
        max-width: 250px;
      }

      @media only screen and (min-width: 900px) {
        max-width: 330px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
