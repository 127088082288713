#top-menu {
  padding: 0;

  > .container {
    padding-bottom: 15px;
    padding-top: 10px;
  }

  &:after {
    background: $theme-primary;
    background: linear-gradient(to right, $theme-primary 0%,$theme-primary 50%,$theme-secondary 50%,$theme-secondary 100%);
    content: ' ';
    display: block;
    height: 2px;
    width: 100%;
  }
}

.links-nav {
  border-bottom: 2px solid $theme-secondary;

  li.nav-item .nav-link {
    color: $theme-secondary;
    margin-bottom: -2px;

    &:not(.disabled) {
      cursor: pointer;
    }

    &.active {
      border-bottom: 2px solid $theme-primary;
      color: $theme-primary;
    }
  }
}
