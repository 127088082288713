#content {
  margin-top: 60px;
  padding-bottom: 40px;

  /*
  .row {
    margin-bottom: 10px;
    margin-top: 10px;
  }*/

  /*header {
    margin-top: 40px;
  }*/
}

.flex-v-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}
