.login {
  background-color: $theme-primary;
  display: block;
  height: 100%;
  overflow-y: scroll;
  padding-top: 50px;
  position: fixed;
  text-align: center;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding-top: 100px;
  }

  #content {
    background-color: transparent;
    box-shadow: none;
    margin-top: 0;

    @media screen and (min-width: 768px) {
      margin-top: 60px;
    }
  }

  label,
  legend,
  p,
  a:not(.btn) {
    color: #fff;
  }

  select {
    border-color: #ddd;
    color: #fff;
  }

  select:focus {
    background-color: $theme-primary;
    color: #fff;
  }

  .form-control:not(select) {
    border-color: #ddd;
    color: #fff;

    &:focus {
      border-color: #fff;
      color: #fff;
    }
  }

  fieldset {
    margin-top: 50px;
  }

  legend {
    font-size: 2rem;
    margin: 15px 0;
  }

  .text-details,
  .text-muted {
    color: #fff !important;
  }

  ::-webkit-input-placeholder {
    color:#fff !important;
    font-weight: 300;
  }

  // Firefox 19+
  ::-moz-placeholder {
    color:#fff !important;
    font-weight: 300;
  }

  // IE
  :-ms-input-placeholder {
    color:#fff !important;
    font-weight: 300;
  }

  input:-moz-placeholder {
    color:#fff !important;
    font-weight: 300;
  }

}
