@font-face {
  font-family: 'Signika Negative';
  src: local('Signika Negative Light'), local('SignikaNegative-Light'), url('../font/Signika_Negative/SignikaNegative-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Signika Negative';
  src: local('Signika Negative Regular'), local('SignikaNegative-Regular'), url('../font/Signika_Negative/SignikaNegative-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Signika Negative';
  src: local('Signika Negative SemiBold'), local('SignikaNegative-SemiBold'), url('../font/Signika_Negative/SignikaNegative-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Signika Negative';
  src: local('Signika Negative Bold'), local('SignikaNegative-Bold'), url('../font/Signika_Negative/SignikaNegative-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

body,
p,
a,
button,
input,
li,
div {
  font-family: 'Signika Negative', sans-serif;
}

body {
  background-color: #FFFDFA;
  color: #737373;
}

a, a:not([href]) {
  cursor: pointer;

  color: $theme-primary;

  &:active,
  &:focus,
  &:hover {
    color: darken($theme-primary, 20);
  }
}