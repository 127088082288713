.btn {
  border-radius: $border-radius;
  cursor: pointer;
  font-weight: 300;
  padding: 10px 25px;

  @media screen and (min-width: 640px) {
    padding: 10px 35px;
  }

  &.btn-sm {
    // Make the btn-sm behave like xs for smaller devices
    font-size: .8rem;
    padding: 4px 15px;

    @media screen and (min-width: 768px) {
      font-size: .875rem;
      padding: 7px 20px;
    }
  }

  &.btn-lg {
    padding: 15px 55px;
  }

  &.btn-xs {
    font-size: 0.8rem;
    padding: 4px 15px;
  }
}

@each $color, $theme-color in $colors {
  .btn-#{$color} {
    background-color: $theme-color;
    border-color: $theme-color;

    &:active,
    &:focus,
    &:hover {
      background-color: darken($theme-color, 10);
      border-color: darken($theme-color, 10);
    }

    &:disabled {
      background-color: #001215;
      border-color: #001215;
    }
  }

  .btn-outline-#{$color} {
    border-color: $theme-color;
    color: $theme-color;

    &.active,
    &:active,
    &:focus,
    &:hover {
      background-color: $theme-color;
      color: #fff;
      outline: 0;
    }
  }
}

.btn-secondary {
  color: #fff;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: #fff !important;
  }
}

.btn-outline-white {
  border-color: #fff;
  color: #fff;

  &.active,
  &:active,
  &:focus,
  &:hover {
    background-color: $theme-primary;
    color: #fff;
    outline: 0;
  }
}

.btn-white {
  background-color: #fff;
  color: $theme-primary;
}

.btn-icon-circle {
  display: inline-block;
  letter-spacing: 1px;
  max-width: none;
  padding: 0;
  text-align: center;

  // Phone and up
  $size: 40px;
  font-size: 1rem;
  height: $size;
  line-height: $size;
  width: $size;
}

.filter-group .btn {
  border-radius: 10px;
  text-transform: none;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
