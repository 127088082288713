form {
  margin: 20px 0;
}

label {
  color: #4A4A4A;
}

.form-group {
  margin-bottom: 2rem;
}

.form-control {
  background-color: transparent;
  border-width: 1px;
  border-radius: 4px;
  padding: .5rem .75rem;

  &:focus {
    background-color: transparent;
    border-color: $theme-info;
  }
}

legend {
  font-size: 1.2rem;
  font-weight: 300;
}

// https://proto.io/freebies/onoff/
.toggle {
  display: inline-block;
  position: relative;
  width: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.toggle-checkbox {
  display: none;
}

.toggle-label {
  background-color: $gray-light;
  border-radius: 20px;
  border: 0px solid #FFFFFF;
  cursor: pointer;
  display: block;
  height: 10px;
  line-height: 10px;
  margin-bottom: 0;
  overflow: hidden;
  padding: 0;
}

.toggle-label:before {
  background: $gray;
  border-radius: 20px;
  bottom: 0;
  box-shadow: 0 6px 12px 0px #757575;
  content: "";
  display: block;
  margin: -5px;
  position: absolute;
  right: 21px;
  top: 0;
  width: 20px;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: lighten($theme-primary, 10);
}

.toggle-checkbox:checked + .toggle-label,
.toggle-checkbox:checked + .toggle-label:before {
   border-color: lighten($theme-primary, 10);
}

.toggle-checkbox:checked + .toggle-label .toggle-inner {
  margin-left: 0;
}

.toggle-checkbox:checked + .toggle-label:before {
  background-color: $theme-primary;
  box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
  right: 0px;
}

.shift-checkbox:checked + .toggle-label {
  background-color: #E1BB7B;
}

.shift-checkbox:checked + .toggle-label,
.shift-checkbox:checked + .toggle-label:before {
   border-color: #E1BB7B;
}

.shift-checkbox:checked + .toggle-label .toggle-inner {
  margin-left: 0;
}

.shift-checkbox:checked + .toggle-label:before {
  background-color: #F5A623;
  box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
  right: 0px;
}

// Range slider
// https://codepen.io/seanstopnik/pen/CeLqA
$range-width: 100% !default;
$range-handle-color: $theme-primary !default;
$range-handle-color-hover: darken($theme-primary, 20) !default;
$range-handle-size: 20px !default;
$range-track-color: $gray-light !default;
$range-track-height: 5px !default;
$range-label-color: $theme-primary !default;
$range-label-width: 60px !default;

.range-slider {
  width: $range-width;
}

.range-slider__range {
  -webkit-appearance: none;
  background: $range-track-color;
  border-radius: 5px;
  height: $range-track-height;
  margin: 0;
  outline: none;
  padding: 0;
  width: calc(100% - (#{$range-label-width + 13px}));

  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    background: $range-handle-color;
    border-radius: 50%;
    cursor: pointer;
    height: $range-handle-size;
    transition: background .15s ease-in-out;
    width: $range-handle-size;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $range-handle-color-hover;
  }

  &::-moz-range-thumb {
    background: $range-handle-color;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    height: $range-handle-size;
    transition: background .15s ease-in-out;
    width: $range-handle-size;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-moz-range-thumb {
    background: $range-handle-color-hover;
  }
}

// Range Label
.range-slider__value {
  background: $range-label-color;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  line-height: 20px;
  margin-left: 8px;
  padding: 5px 10px;
  position: relative;
  text-align: center;
  width: $range-label-width;

  &:after {
    border-bottom: 7px solid transparent;
    border-right: 7px solid $range-label-color;
    border-top: 7px solid transparent;
    content: '';
    height: 0;
    left: -7px;
    position: absolute;
    top: 8px;
    width: 0;
  }
}

// Firefox Overrides
::-moz-range-track {
    background: $range-track-color;
    border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}
