.card-person {
  color: $gray-light;

  .card-body > .row > div {
    padding-left: 5px;
    padding-right: 5px;
  }

  .person-name {
    color: $theme-primary;
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 3px;
    margin: 3px 0;

    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }

    .dot {
      margin-right: 5px;
    }
  }

  .person-details {
    li,
    .text-details {
      font-size: 0.7rem;

      @media screen and (min-width: 768px) {
        font-size: 0.9rem;
      }
    }
  }

  .person-rate {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 0;

    @media screen and (min-width: 768px) {
      font-size: 1.3rem;
    }
  }

  .person-actions {
    text-align: center;

    @media screen and (min-width: 768px) {
      margin-top: 10px;
    }

    p {
      margin-bottom: 5px;
    }
  }

}
