h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 15px 0;
}

.text-details {
  color: $gray-light;
  font-size: 0.9rem;
  font-weight: 300;
}

@each $type, $color in $colors {
  .text-#{$type} {
    color: $color !important;
  }
}

.text-sm {
  font-size: 0.9rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $theme-primary;
  font-weight: 600;
}

h1 { font-size: 1.4em; }
h2 { font-size: 1.3em; }
h3 { font-size: 1.2em; }
h4 { font-size: 1.1em; }
h5 { font-size: 1em; }
h6 { font-size: 0.9em; }

@media screen and (min-width: 768px) {
  h1 { font-size: 1.9em; }
  h2 { font-size: 1.8em; }
  h3 { font-size: 1.7em; }
  h4 { font-size: 1.6em; }
  h5 { font-size: 1.5em; }
  h6 { font-size: 1.4em; }
}
