@font-face {
  font-family: 'asahi';
  src: url('../font/asahi/asahi.eot?19353234');
  src: url('../font/asahi/asahi.eot?19353234#iefix') format('embedded-opentype'),
       url('../font/asahi/asahi.woff2?19353234') format('woff2'),
       url('../font/asahi/asahi.woff?19353234') format('woff'),
       url('../font/asahi/asahi.ttf?19353234') format('truetype'),
       url('../font/asahi/asahi.svg?19353234#asahi') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'asahi';
    src: url('../font/asahi.svg?19353234#asahi') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "asahi";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-search:before { content: '\e800'; }
.icon-down:before { content: '\e802'; }
.icon-left:before { content: '\e803'; }
.icon-right:before { content: '\e804'; }
.icon-up:before { content: '\e805'; }
.icon-sun:before { content: '\e806'; }
.icon-moon:before { content: '\e807'; }
.icon-star:before { content: '\e808'; }
.icon-star-empty:before { content: '\e809'; }
.icon-heart:before { content: '\e80a'; }
.icon-heart-empty:before { content: '\e80b'; }
.icon-cancel:before { content: '\e80c'; }
.icon-comment:before { content: '\e80e'; }
.icon-user:before { content: '\e810'; }
.icon-bookmark:before { content: '\f274'; }
.icon-clock:before { content: '\e812'; }
.icon-spin:before { content: '\e834'; }
.icon-home:before { content: '\f00a'; }
.icon-file-text:before { content: '\f0f6'; }
.icon-star-half:before { content: '\f123'; }
.icon-calendar:before { content: '\f133'; }
.icon-file-pdf:before { content: '\f1c1'; }
.icon-file-word:before { content: '\f1c2'; }
.icon-file-image:before { content: '\f1c5'; }
