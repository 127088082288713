.booking-status {
  background-color: $gray-light;
  color: #fff;
  display: inline-block;
  letter-spacing: 1px;
  margin-bottom: 10px;
  max-width: none;
  padding: 0;
  text-align: center;

  // Phone and up
  $size: 40px;
  font-size: 1rem;
  height: $size;
  line-height: $size;
  width: $size;

  // Tablet and up
  @media screen and (min-width: 768px) {
    $size: 80px;
    font-size: 2rem;
    height: $size;
    line-height: $size;
    width: $size;
  }

  &.pending {
    background-color: $theme-warning;
  }

  &.accepted {
    background-color: $theme-success;
  }

  &.declined {
    background-color: $theme-danger;
  }


}