.average-rating {
  .rating-count {
    color: $theme-secondary;
    display: none;
    font-size: 1.2rem;
    font-weight: 600;

    @media screen and (min-width: 768px) {
      display: inline-block;
    }
  }

  i {
    color: $theme-secondary;
    font-size: 13px;

    &:before {
      margin: 0;
    }
  }
}
