$calendar-colors: (available: $theme-success, unavailable: $theme-danger, booked: $theme-warning, selected: $theme-primary, online: $theme-success, offline: $theme-danger);

table.table.calendar {

	table-layout: fixed;
	border-collapse: separate;
	border-spacing: 0px 4px;

	td {
    color: #4A4A4A;
    font-weight: 600;
	}

	th {
    color: $theme-primary;
    font-weight: 400;
	}

  & th, & td {
    border: none;
    font-size: 1em;
    padding: .5em;
    text-align: center;
    vertical-align: center;
    width: 100%;

    @media screen and (min-width: 768px) {
      font-size: 1.3em;
    }

    @each $type, $color in $calendar-colors {
			&.#{$type} {
				color: $color;

					&:hover {
						cursor: pointer;
					}
			}
		}

		&.past {
			color: #8D8D8D;
      font-weight: 300;

			&:hover {
				cursor: text;
			}
		}
	}
}

.dot {
  $size: 8px;

  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  height: $size;
  line-height: $size;
  text-align: center;
  width: $size;

  &.dot-big {
    $size: 18px;

    height: $size;
    line-height: $size;
    width: $size;
  }

  @each $type, $color in $colors {
    &.dot-#{$type} {
      border-color: $color;
      background-color: $color;
      color: $color;
    }
  }

  &.available,
  &.online {
	  border-color: $theme-success;
    background-color: $theme-success;
    color: $theme-success;
  }

  &.unavailable,
  &.offline {
	  border-color: $theme-danger;
    background-color: $theme-danger;
    color: $theme-danger;
  }

  &.booked {
	  border-color: $theme-warning;
    background-color: $theme-warning;
    color: $theme-warning;
  }
}

.filter-group {
  margin: 20px 0;
}

// The Dark theme
.calendar-dark {
  background-color: #4A4A4A;
  color: #fff;

  table.table.calendar th {
    color: #fff;
  }

  table.table.calendar td.past {
    background-color: transparent;
    color: #ddd;
  }

  .text-details {
    color: #fafafa;
  }

  .filter-group .btn-outline-primary {
    border-color: #fff;
    color: #fff;

    &.active,
    &:active,
    &:focus,
    &:hover {
      background-color: #fff;
      color: $theme-primary;
      outline: 0;
    }
  }
}
