
.file-icon {  
  color: $theme-info;
  display: inline-block;
  letter-spacing: 1px;
  max-width: none;
  padding: 0;
  text-align: center;
  text-decoration: none;

  // Phone and up
  $size: 64px;
  font-size: 1rem;
  height: $size;
  line-height: $size;
  width: $size;

  // Tablet and up
  @media screen and (min-width: 768px) {
    $size: 80px;
    font-size: 2rem;
    height: $size;
    line-height: $size;
    width: $size;
  }


}

a.learn-more, a:not([href]).learn-more {
  cursor: pointer;
  
  color: $theme-primary !important;

  text-decoration: underline !important;

  &:active,
  &:focus,
  &:hover {
    color: darken($theme-primary, 20) !important;
  }
}