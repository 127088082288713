.card {
  box-shadow: $card-shadow;

  &.border-secondary {
    border-color: $theme-secondary !important;
  }

  .card-header {
    padding: .35rem 1.25rem .25rem 1.25rem;

    &.bg-danger,
    &.bg-info,
    &.bg-success,
    &.bg-warning {
      color: #fff;
    }
  }
}

form .card-header {
  background-color: #EEEEEE;
  color: $theme-primary;

  & > label {
    margin: 0px;
    color: $theme-primary;
  }
}
