.navbar-light {
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.12);
}

.navbar {
  span.text-muted {
    font-size: 16px;
    font-size: 1.6rem;
    padding: .5em 0;
  }
}

.navbar.navbar-light.fixed-top {
  .img-logo {
    margin-top: 5px;
    max-height: 30px;
  }

  .nav-item.nav-avatar {
    .nav-link {
      padding: 0;
    }
  }

  .nav-link {
    color: $theme-primary;

    &.active {
      background: transparent;
      color: $theme-secondary;
    }
  }
}